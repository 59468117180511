import React from 'react';
import Sidebar from './SideBar';
import MapPopup from './MapPopup';
import { Icon, Modal, Header, Button, Popup } from 'semantic-ui-react'
import ReactMapGl, {Marker, NavigationControl, FlyToInterpolator} from 'react-map-gl';
import *  as allLocations from './data/locationData.json';

const Map = () => {
  let filteredLocations=[];

  const [viewPort, setViewPort] = React.useState({
    latitude: 0.336765,
    longitude: 34.169276,
    zoom: 9.9,
    bearing: 0,
    pitch: 0,
    transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
    transitionDuration: 'auto'
  })

  const [filters, setFilters] = React.useState({
    "type": [],
    "challenges": [],
    "shared": [],
    "country": [],
    "round": []
  });
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [showStories, setShowStories] = React.useState(false);
  const [selectedStory, setSelectedStory] = React.useState(null);
  const [mapStyle, setMapStyle] = React.useState('mapbox://styles/micahoriaso/ck0y66q3n042q1cqszv749n02');
  const [isModalOpen, setModalOpen] = React.useState(false);
  const mapRef = React.useRef(null);

  const [locationData, setLocationData] = React.useState({
    "type": "FeatureCollection",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": []
  });

  // React.useEffect(() => {

  //   const map = mapRef.current.getMap();
    
  //   map.on('load', function () {
  //     // Add a new source from our GeoJSON data and set the
  //     // 'cluster' option to true. GL-JS will add the point_count property to your source data.
  //     map.addSource("earthquakes", {
  //       type: "geojson",
  //       // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
  //       // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
  //       // data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson",
  //       data: locationData,
  //       cluster: true,
  //       clusterMaxZoom: 14, // Max zoom to cluster points on
  //       clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
  //     });


  //     map.addLayer({
  //       id: "clusters",
  //       type: "circle",
  //       source: "earthquakes",
  //       filter: ["has", "point_count"],
  //       paint: {
  //         // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
  //         // with three steps to implement three types of circles:
  //         //   * Blue, 20px circles when point count is less than 100
  //         //   * Yellow, 30px circles when point count is between 100 and 750
  //         //   * Pink, 40px circles when point count is greater than or equal to 750
  //         "circle-color": [
  //           "step",
  //           ["get", "point_count"],
  //           "#51bbd6",
  //           100,
  //           "#f1f075",
  //           200,
  //           "#f28cb1"
  //         ],
  //         "circle-radius": [
  //           "step",
  //           ["get", "point_count"],
  //           20,
  //           100,
  //           30,
  //           200,
  //           40
  //         ]
  //       }
  //     });

  //     map.addLayer({
  //       id: "cluster-count",
  //       type: "symbol",
  //       source: "earthquakes",
  //       filter: ["has", "point_count"],
  //       layout: {
  //         "text-field": "{point_count_abbreviated}",
  //         "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
  //         "text-size": 12
  //       }
  //     });

  //     map.addLayer({
  //       id: "unclustered-point",
  //       type: "circle",
  //       source: "earthquakes",
  //       filter: ["!", ["has", "point_count"]],
  //       paint: {
  //         "circle-color": "#11b4da",
  //         "circle-radius": 10,
  //         "circle-stroke-width": 1,
  //         "circle-stroke-color": "#fff"
  //       }
  //     });

  //     // inspect a cluster on click
  //     map.on('click', 'clusters', function (e) {
  //       var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
  //       var clusterId = features[0].properties.cluster_id;
  //       map.getSource('earthquakes').getClusterExpansionZoom(clusterId, function (err, zoom) {
  //         if (err)
  //           return;

  //         map.easeTo({
  //           center: features[0].geometry.coordinates,
  //           zoom: zoom
  //         });
  //       });
  //     });

  //     map.on('mouseenter', 'clusters', function () {
  //       map.getCanvas().style.cursor = 'pointer';
  //     });
  //     map.on('mouseleave', 'clusters', function () {
  //       map.getCanvas().style.cursor = '';
  //     });
  //   });
    

  // }, [locationData]);

  const handleChangeMapStyle = (e, {value}) => {
    setMapStyle(value);
  }

  const handleOnchange = (e, {value, name, checked}) => {
    if (checked) {
      filters[name] = [...filters[name], value];
      setFilters({
        ...filters,
      });
    }
    else {
      filters[name] = filters[name].filter(item => item !== value);
      setFilters({
        ...filters
      })
    }

    if (
        filters.challenges.length === 0 &&
        filters.country.length === 0 &&
        filters.round.length === 0 &&
        filters.shared.length === 0 &&
        filters.type.length === 0) {
      return setLocationData({
        ...locationData,
        features: []
      })
    }
    setLocationData({
      ...locationData,
      features: [...filterLocation()]
    })
  }

  const getPinIcon = (locationType) => {
    let pin  = {
      icon: "map marker",
      color: "blue"
    };
    switch (locationType) {
      case 'Administrative Offices and Services across the Border' :
        pin = {
          icon: "building",
          color: "black"
        };
        break;
      case 'Business and Financial Service Intitutions/Services' :
        pin = {
          icon:"dollar sign",
          color: "green"
        };
        break;
      case 'Education Facilities and Institutions' :
        pin = {
          icon:"university",
          color: "brown"
        };
        break;
      case 'Entertainment and Hospitality Facilities and Services' :
        pin = {
          icon:"utensils",
          color: "purple"
        };
        break;
      case 'Health Facilities, Centres and institutions' :
        pin = {
          icon:"hospital symbol",
          color: "olive"
        };
        break;
      case 'Roads, Bridges and other Infastructure' :
        pin = {
          icon:"road",
          color: "brown"
        };
        break;
      case 'Market and Trade Facilities' :
        pin = {
          icon:"shopping cart",
          color: "orange"
        };
        break;
      case 'Natural Resource eg Water Source, Lake, Swamp, Mineral Deposit' :
        pin = {
          icon:"leaf",
          color: "green"
        };
        break;
      case 'NGO/FBO/Society or Community Focus/Based Organizations' :
        pin = {
          icon:"users",
          color: "teal"
        };
        break;
      case 'Religious Intitutions and Services (Church, Mosques)' :
        pin = {
          icon:"plus",
          color: "purple"
        };
        break;
      case 'Social Amenities and Services' :
        pin = {
          icon:"id card",
          color: "grey"
        };
        break;
      default:
        break;
    }

    return pin

  }
  const filterByType = (location) => {
    if (filters.type.length > 0){
      return filters.type.includes(location.properties.Facility_Service_type);
    }
    else {
      return true
    }
  }

  const filterByChallenges = (location) => {
    if (filters.challenges.length > 0){
      return filters.challenges.includes(location.properties.Challenges_facility_sharing_present);
    }
    else {
      return true
    }
  }
  const filterByCountry = (location) => {
    if (filters.country.length > 0){
      return filters.country.includes(location.properties.Country);
    }
    else {
      return true
    }
  }
  const filterByRound = (location) => {
    if (filters.round.length > 0){
      return filters.round.includes(location.properties.Round_of_Mapping);
    }
    else {
      return true
    }
  }

  const filterByIsShared = (location) => {
    if (filters.shared.length > 0){
      return filters.shared.includes(location.properties.Facility_Service_shared_by_people);
    }
    else {
      return true
    }
  }

  const filterLocation = () => {
      filteredLocations = [
        ...allLocations.features.filter (
          location => filterByType(location) && filterByChallenges(location) && filterByCountry(location) && filterByRound(location) && filterByIsShared(location)
        )
      ]
    return filteredLocations;
  }

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedLocation(null);
  }

  const onViewportChange = viewport =>
    setViewPort({...viewPort, ...viewport });

  const goToViewport = ({ longitude, latitude }) => {
    onViewportChange({
      longitude,
      latitude,
      zoom: 14.5,
      transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
      transitionDuration: 'auto'
    });
  };

  const clickStory = story => {
    setSelectedStory(story);
    goToViewport(story);
  }

  return (
  <div className="main-container">
    <div className="map-container">
      <ReactMapGl
        mapStyle={mapStyle}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={viewPort => {setViewPort(viewPort)}}
        width='100%'
        height= '100%'
        {...viewPort}
        ref={mapRef}
      >
       <div className="navigation-control">
            {!showStories && <NavigationControl />}
          <div className='stories-control'>
            <Popup 
              content={`${showStories? 'Hide':'Show'} stories`}
              position='right center'
              inverted
              trigger={
                <Button icon size="mini" color='green' className='stories-control' href='http://dmerl-eassi-stories.netlify.com' target='blank'>
                  <Icon name='book' />
                </Button>
              }
            />
          </div>
        </div>
        {
          // showStories && <div className='stories-container'>
          //   {
          //     !selectedStory && storiesData.stories.map( story => (
          //       <div className="story-title" onClick={() => clickStory(story)}>{story.title}</div>
          //       ))
          //   }
          //   {
          //     selectedStory && 
          //     <div className="story-detail">
          //         <Button size="tiny" icon labelPosition='left' onClick={() => setSelectedStory(null)}>
          //         <Icon name='arrow left' />
          //         All Stories
          //       </Button>
          //       <Card fluid>
          //         <Card.Content header>
          //           <Card.Header>
          //             {selectedStory.title}
          //           </Card.Header>
          //           <Card.Meta>
          //             {selectedStory.type}
          //             <div>Interview Date: {selectedStory.interviewDate}</div>
          //           </Card.Meta>
          //         </Card.Content>
          //         <Card.Content header>
          //           {selectedStory.where}
          //         </Card.Content>
          //         <Card.Content>
          //           <Card.Description>
          //               {selectedStory.narrative.split('\n').map((item, key) => {
          //                 return <React.Fragment key={key}>{item}<br /></React.Fragment>
          //               })}
          //           </Card.Description>
          //         </Card.Content>
          //         <Card.Content extra>
          //           {selectedStory.narrators.map(narrator => <div>{narrator}</div>)}
          //         </Card.Content>
          //       </Card>
          //     </div>
          //   }
          // </div>
        }
        {
          locationData.features.length > 0 && locationData.features.map(
            (location, index) => (
              <Marker
                key={index}
                longitude={location.geometry.coordinates[0]}
                latitude={location.geometry.coordinates[1]}
              >
              <Icon
                circular
                inverted
                className="location-pointer"
                name={getPinIcon(location.properties.Facility_Service_type).icon}
                color={getPinIcon(location.properties.Facility_Service_type).color}
                onClick={ e => {
                  e.preventDefault();
                  setSelectedLocation(location)
                  setModalOpen(true)
                }}
              />
              {/* <div
                className="location-pointer"
                style={{ backgroundColor: getPinIcon(location.properties.Facility_Service_type).color}}
                onClick={ e => {
                  e.preventDefault();
                  setSelectedLocation(location)
                  setModalOpen(true)
                }}
              /> */}
              </Marker>
            )
          )
        }
        {selectedLocation &&
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            size='tiny'
            dimmer='blurring'
            closeIcon
          >
            <Header content={selectedLocation.properties.Facility_Service_Name} />
            <Modal.Content>
              <Modal.Description>
                <MapPopup
                  selectedLocation={selectedLocation}
                />
              </Modal.Description>
            </Modal.Content>
          </Modal>
        }
      </ReactMapGl>
    </div>
    <div className="controls-container">
      <Sidebar
        handleOnchange={handleOnchange}
        handleChangeMapStyle={handleChangeMapStyle}
        locationData={locationData}
        mapStyle={mapStyle}
      />
    </div>
  </div>
  )
}

export default Map;