import React from 'react';
import PopupListItem from './PopupListItem'


const MapPopup = ({selectedLocation}) => (
    <div className="map-popup">
      <PopupListItem
        propertyKey={"Facility/Service Type:"}
        propertyValue={selectedLocation.properties.Facility_Service_type}
      />
      {/* <PopupListItem
        propertyKey={"Broad category:"}
        propertyValue={selectedLocation.properties.Broad_Category}
      /> */}
      <PopupListItem
        propertyKey={"Country:"}
        propertyValue={selectedLocation.properties.Country}
      />
      <PopupListItem
        propertyKey={"Centre:"}
        propertyValue={selectedLocation.properties.Centre}
      />
      <PopupListItem
        propertyKey={"Facility/Service shared accross border:"}
        propertyValue={selectedLocation.properties.Facility_Service_shared_by_people}
      />
      <PopupListItem
        propertyKey={"Why Facility/Service is not shared across border:"}
        propertyValue={selectedLocation.properties.Facility_Service_not_shared_by_people_reason}
      />
      <PopupListItem
        propertyKey={"People served at facility per day:"}
        propertyValue={selectedLocation.properties.People_served_at_Facility_Per_Day}
      />
      <PopupListItem
        propertyKey={"Challenge present with sharing resource:"}
        propertyValue={selectedLocation.properties.Challenges_facility_sharing_present}
      />
      <PopupListItem
        propertyKey={"Specific challenge:"}
        propertyValue={selectedLocation.properties.Challenges}
      />
      <PopupListItem
        propertyKey={"Ongoing efforsts to solve challenges:"}
        propertyValue={selectedLocation.properties.Efforts_solutions_ongoing}
      />
      <PopupListItem
        propertyKey={"Driver of peace at facility:"}
        propertyValue={selectedLocation.properties.Driver_of_peace}
      />
      <PopupListItem
        propertyKey={"Recommendations:"}
        propertyValue={selectedLocation.properties.Recommendations}
      />
      <PopupListItem
        propertyKey={"Benefit of facility across border:"}
        propertyValue={selectedLocation.properties.Benefits_across_border}
      />
      {/* <PopupListItem
        propertyKey={"Phase of mapping:"}
        propertyValue={selectedLocation.properties.Round_of_Mapping}
      /> */}
      <PopupListItem
        propertyKey={"Altitude:"}
        propertyValue={selectedLocation.properties.Altitude}
      />
    </div>
);

export default MapPopup;